import { useEffect } from "react";
import { useStore } from "../stores/useStore";

export const usePollingManager = () => {
  const { startPolling, stopPolling, fetchEmailContent, generateNewEmail } =
    useStore();

  useEffect(() => {
    const initializeEmail = async () => {
      const stored = localStorage.getItem("tempEmail");

      if (stored) {
        const data = JSON.parse(stored);
        const isExpired = new Date(data.expiresAt) <= new Date();
        const isValid = data.isBookmarked || !isExpired;

        if (data.email) {
          if (isValid) {
            await fetchEmailContent();
            startPolling();
          } else {
            await fetchEmailContent();
          }
        }
      } else {
        await generateNewEmail();
        startPolling();
      }
    };

    initializeEmail().catch(console.error);

    // Cleanup on unmount
    return () => {
      stopPolling();
    };
  }, []); // Empty dependency array - only run on mount/unmount
};
